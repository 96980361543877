<template>
  <list-container-widget
    id="forums"
    :title="widgetName"
    :app-id="28"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="forums" />
      <CreateNewButton item-type="forums" />
    </template>
    <!-- Loading -->
    <b-spinner
      v-if="isLoading"
      type="grow"
      small
      class="mx-auto d-block"
    />

    <!-- Data loaded -->
    <template v-else>
      <div
        v-if="items && items.length > 0"
        id="scrollContainer"
        class="items-container-vertical pb-2"
      >
        <!-- Items loop -->
        <!-- TODO: change to :key = "item.key", investigate why it fails, duplicate keys! -->
        <div
          v-for="(item, index) in items"
          :key="index"
          class="x-scroll-element mx-0"
        >
          <b-card v-if="canIndex(item)" class="card-question" no-body>
            <b-link :to="{ name: 'forum', params: { id: item.key } }">
              <!-- Right: Main content -->
              <div class="px-2 pb-1 d-flex align-items-center">
                <div class="w-100">
                  <div class="text-secondary pt-1 font-weight-normal">
                    <span v-if="item.createdByUser">
                      <b-avatar
                        size="38"
                        :src="getImageSrc(item.createdByUser.avatarURL)"
                        :alt="item.createdByUser.name"
                        class="user-avatar mr-50"
                      />
                      {{ $t("common.created-by") }}
                      {{ item.createdByUser.name }}
                      {{ item.createdByUser.surname }}
                      <b-badge
                        v-if="item.status"
                        class="float-right mt-50"
                        :variant="getBadgeVariant(item.status)"
                      >
                        {{ getBadgeText(item.status) }}
                      </b-badge>
                    </span>
                  </div>
                  <h4 class="font-weight-extrabold pl-4 mt-1 mb-50">
                    {{
                      item.name[currentLocale] || Object.values(item.name)[0]
                    }}
                  </h4>
                  <p
                    v-if="item.description"
                    class="pl-4 mb-1 html-text-ellipsis-3 text-secondary content-container"
                    v-html="
                      item.description[currentLocale] ||
                        Object.values(item.description)[0]
                    "
                  />
                  <b-card-text class="w-100 pl-4 mt-2 text-muted">
                    <span>
                      <feather-icon
                        size="20"
                        :class="
                          item.likedByMember
                            ? 'profile-likes-primary'
                            : 'profile-icon'
                        "
                        icon="ThumbsUpIcon"
                        class="mr-25"
                        @click.stop.prevent="toggleLike(item.key)"
                      />
                      {{ item.totalLikes }}
                    </span>

                    <span class="mx-1">
                      <feather-icon
                        role="button"
                        size="20"
                        icon="MessageSquareIcon"
                        class="mr-25"
                      />
                      {{ item.answerCount || 0 }}
                    </span>

                    <span>
                      <feather-icon size="20" icon="ClockIcon" class="mr-25" />
                      {{ timestampToNow(item.createdAt) }}
                    </span>
                  </b-card-text>
                </div>
              </div>
            </b-link>
          </b-card>
          <div v-else>
            <b-card>
              <p class="font-weight-extrabold pl-4 mt-1 mb-50">
                {{ $t("item-no-permission") }}
              </p>
            </b-card>
          </div>
        </div>
        <b-spinner
          v-if="isLoadingNextPage"
          type="grow"
          small
          class="mx-auto my-2 d-block"
        />
        <b-button
          v-else-if="items && items.length < total"
          class="d-block text-center m-auto"
          variant="link"
          :disabled="isLoadingNextPage"
          @click="handleLoadOfNewItems"
        >
          {{ $t("action.load-more") }}
        </b-button>
      </div>

      <!-- Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="placeholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: widgetName }) }}
          </p>
        </b-col>
      </b-row>
    </template>

  </list-container-widget>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
// import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
// import { quillEditor } from 'vue-quill-editor';
import moment from 'moment';
import { getImageResource } from '@/@core/utils/image-utils';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ForumPlaceholder from '@/assets/images/placeholders/light/forums.svg';
// import FileTypes from '@/@core/constants/FileTypes';
// import FileUpload from '@core/components/files/FileUpload.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';

export default {
  name: 'ForumsListWidget',
  components: {
    ListContainerWidget,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    CreateNewButton: () => import ("@/@core/components/widget-actions/CreateNewButton.vue"  /* webpackChunkName: "CreateNewButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      projects: [],
      imageSrc: null,
      imageForum: null,
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      questionName: '',
      searchInput: '',
      results: [],
      form: {},
    };
  },
  computed: {
    FileTypes() {
      return FileTypes;
    },
    // Load data from store
    itemsData() {
      return this.$store.getters.forums.list;
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData?.unpaginated;
      return itemsToIterate;
    },
    size() {
      return this.items?.length;
    },
    total() {
      return this.itemsData.meta.total;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    placeholder() {
      return ForumPlaceholder;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
  },
  watch: {
    imageForum(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    canIndex(item) {
      if (
        this.loggedUser?.key === item?.createdByUser?.key
        || checkPermissions(
          'index',
          'forum',
          this.loggedMemberRoles,
          this.collective,
        )
      ) {
        return true;
      }
      return false;
    },
    timestampToNow(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).fromNow();
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    getBadgeVariant(status) {
      if (!status || status === 42658) {
        return 'warning';
      }
      if (status == 42659 || status === 42736) {
        return 'success';
      }
    },
    getBadgeText(status) {
      if (!status || status === 42658) {
        return this.$t('forums.open');
      }
      if (status == 42659 || status === 42736) {
        return this.$t('forums.closed');
      }
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format('DD-MM-YYYY');
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'forums',
        storedKey: 'list',
        page: this.lastLoadedPage,
        forceAPICall: true,
        requestConfig: {
          count: 16,
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    // TODO: fix onScroll
    async onScroll(event) {
      const distanceToEnd = event.srcElement.scrollHeight
        - event.srcElement.scrollTop
        - event.srcElement.offsetHeight;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length <= total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    async toggleLike(key) {
      // TODO: Check with BE itemTypes and morphTypes
      await this.$store.dispatch('toggleLike', {
        itemType: 'forums',
        morphType: 'forum',
        storedKey: 'list',
        key,
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      // TODO: improve search, think well the multilanguage
      this.results = this.itemsData.unpaginated.filter((item) => (Object.values(item.description)[0] !== undefined
        ? Object.values(item.description)[0]
          .replace(/<[^>]+>/g, '')
          .toLowerCase()
          .includes(searchTerm)
            || Object.values(item.name)[0].toLowerCase().includes(searchTerm)
        : Object.values(item.name)[0].toLowerCase().includes(searchTerm)));
    },
  },
};
</script>
<style>
.content-container img {
  max-width: 100%;
}
</style>
